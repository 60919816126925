import { ny } from '~/lib/utils'
import Marquee from '~/components/ui/marquee'

const reviews = [
   {
      name: 'Vineet',
      username: '@vineet',
      body: 'ESL AI Agents have transformed our outreach! Seamless, efficient, and personalized interactions every time. Game changer for our campaigns! 🚀 #AI #MarketingInnovation',
      img: 'https://res.cloudinary.com/dedkbb3mk/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red,b_rgb:262c35/v1731791324/1646483238013_wsnkxa.jpg',
   },
   {
      name: 'Sidhant',
      username: '@sidhant',
      body: 'Building with ESL AI Agents has been a breeze! They\'ve simplified complex tasks and enhanced our product\'s user experience. Truly next-gen tech. #ProductManagement #AI',
      img: 'https://res.cloudinary.com/dedkbb3mk/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red,b_rgb:262c35/v1731791176/1516630509689_t9jbr3.jpg',
   },
   {
      name: 'Avantisha',
      username: '@avantisha',
      body: 'Working with ESL AI Agents feels like having a creative collaborator! They help refine ideas and accelerate my process. An essential tool for any creator. 💡 #ContentCreation #AI',
      img: 'https://res.cloudinary.com/dedkbb3mk/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red,b_rgb:262c35/v1731791692/WhatsApp_Image_2024-11-17_at_02.43.38_pczpry.jpg',
   },
   {
      name: 'Divi Joshi',
      username: '@divi',
      body: 'Creating content with ESL AI Agents is like having a creative partner! They streamline ideas and speed up my workflow. A must-have tool. 💡 #ContentCreation #AI',
      img: 'https://media.licdn.com/dms/image/D4E03AQFiPWzpc1dCSw/profile-displayphoto-shrink_200_200/0/1674850319490?e=2147483647&v=beta&t=HnvjXvLX9WGt7P0KJylC6MyAoMU7d_EBo_zkoxu1ixg',
   },
   {
      name: 'Shilpa',
      username: '@shilpa',
      body: 'Recruiting with ESL AI Agents feels like having a dedicated assistant! They simplify candidate sourcing, optimize workflows, and make the hiring process faster than ever. An essential tool for any recruiter. 💼 #RecruitmentTech #AI',
      img: 'https://res.cloudinary.com/dedkbb3mk/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red,b_rgb:262c35/v1731791422/images_j9qk41.jpg',
   },
   {
      name: 'Adityan',
      username: '@adityan',
      body: 'Thanks to ESL AI Agents, we’re reaching the right audience faster than ever. Their precision and efficiency are unmatched! 📈 #AIinMarketing #GrowthHacks',
      img: 'https://res.cloudinary.com/dedkbb3mk/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red,b_rgb:262c35/v1731791376/1641371874406_c0c4io.jpg',
   },
   {
      name: 'savio D souza',
      username: '@savio',
      body: 'With ESL AI Agents, we connect with the right clients effortlessly and faster than ever. Their accuracy and efficiency elevate our concierge services to the next level! 🌟 #AIinServices #ClientExperience',
      img: 'https://res.cloudinary.com/dedkbb3mk/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red,b_rgb:262c35/v1731792716/KEN_2992_yff6xn.jpg',
   },
   
]

const firstRow = reviews.slice(0, reviews.length / 2)
const secondRow = reviews.slice(reviews.length / 2)

function ReviewCard({
   img,
   name,
   username,
   body,
}: {
   img: string
   name: string
   username: string
   body: string
}) {
   return (
      <figure
         className={ny(
            'relative h-48 w-40 cursor-pointer overflow-hidden rounded-xl border p-4',
            // light styles
            'border-gray-950/[.1] bg-gray-950/[.01] hover:bg-gray-950/[.05]',
            // dark styles
            'dark:border-gray-50/[.1] dark:bg-gray-50/[.10] dark:hover:bg-gray-50/[.15]',
         )}
      >
         <div className="flex flex-row items-center gap-2">
            <img className="rounded-full" width="32" height="32" alt="" src={img} />
            <div className="flex flex-col">
               <figcaption className="text-sm font-medium dark:text-white">
                  {name}
               </figcaption>
               <p className="text-xs font-medium dark:text-white/40">{username}</p>
            </div>
         </div>
         <blockquote className="mt-2 text-sm">{body}</blockquote>
      </figure>
   )
}

function MarqueeDemoVertical() {
   return (
      <div className="relative flex h-full flex-row items-center justify-center overflow-hidden rounded-lg sm:px-20">
         <Marquee pauseOnHover vertical className="[--duration:20s]">
            {firstRow.map(review => (
               <ReviewCard key={review.username} {...review} />
            ))}
         </Marquee>
         <Marquee reverse pauseOnHover vertical className="[--duration:20s]">
            {secondRow.map(review => (
               <ReviewCard key={review.username} {...review} />
            ))}
         </Marquee>
         <div className="pointer-events-none absolute inset-x-0 top-0 h-1/3 bg-gradient-to-b from-white"></div>
         <div className="pointer-events-none absolute inset-x-0 bottom-0 h-1/3 bg-gradient-to-t from-white"></div>
      </div>
   )
}

export {
    MarqueeDemoVertical
}
